import $ from "jquery";

$(".share .s-title .top-button,.menu .m-top").on("click", (e) => {
  $("body,html").animate(
    {
      scrollTop: 0,
    },
    800,
    "swing",
  );
});

// sp版でメニューを表示、非表示にする
$("#hamburger").on("click", (e) => {
  if ($("#hamburger").data("type") === "open") {
    $("header .menu .line").css("display", "none");
    $("header .menu .content").css("display", "block");
    $("header .menu .cross").css("display", "block");
    $("#hamburger").data("type", "close");
    $("#hamburger").addClass("left-move");
  } else {
    $("header .menu .content").css("display", "none");
    $("header .menu .cross").css("display", "none");
    $("header .menu .line").css("display", "block");
    $("#hamburger").data("type", "open");
    $("#hamburger").removeClass("left-move");
  }
});

//移動したら閉じる
$(".menu-select").on("click", () => {
  $("header .menu .content").css("display", "none");
  $("header .menu .cross").css("display", "none");
  $("header .menu .line").css("display", "block");
  $("#hamburger").data("type", "open");
  $("#hamburger").removeClass("left-move");
});

// サイト下部へ移動
$(".play-now, #kv .bonus").on("click", (e) => {
  $("html, body").animate(
    {
      scrollTop: $("body").get(0).scrollHeight,
    },
    1000,
    "swing",
  );
});

// スムーススクロール;
const smoothScorll = () => {
  // 1. すべてのhref="#"のaタグを取得
  const smoothScrollTrigger = document.querySelectorAll('a[href^="/#"]');
  const currentURL = location.pathname;

  if (currentURL !== "/") return;
  // 2. 1のaタグにそれぞれクリックイベントを設定
  for (let i = 0; i < smoothScrollTrigger.length; i++) {
    smoothScrollTrigger[i].addEventListener("click", (e) => {
      // 3. ターゲットの位置を取得
      e.preventDefault();
      let href = smoothScrollTrigger[i].getAttribute("href");
      let targetElement = document.getElementById(href.replace("/#", ""));

      const rect = targetElement.getBoundingClientRect().top;
      const offset = window.scrollY;
      const target = rect + offset;

      // 4. スムースにスクロール
      window.scrollTo({
        top: target,
        behavior: "smooth",
      });
    });
  }
};

$(document).ready(() => {
  // スクロールイベント
  $(window).scroll(() => {
    var scrollPosition = $(window).scrollTop();
    var hamburger = $("#hamburger");
    var menuContent = $(".menu .content");

    // スクロール位置が39px以上の場合、固定のcssを追加
    if (scrollPosition >= 39) {
      hamburger.css("position", "fixed");
      menuContent.css("position", "fixed")
    } else {
      // それ以外の場合はcssを削除
      hamburger.css("position", "absolute");
      menuContent.css("position", "absolute")
    }
  });
});

smoothScorll();
